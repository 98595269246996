.cta-button {
    padding: 15px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.connect-wallet-button {
    margin-top: 20px;
    background: rgb(32, 129, 226);
}

.wallet-button-active {
    background: orange;
}

.wallet-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 210px;
    position: relative;
}

.wallet-button-connect {
    width: 210px;
}

.wallet-button-connect:hover {
    cursor: pointer;
}

.wallet-button-connected {
    position: relative;
}

.wallet-button-connected:hover {
    cursor: pointer;
}

.wallet-button-wallet-address {
    position: absolute;
    top: 13px;
    left: 45px;
    font-size: 17px;
    color: white;
    user-select: none;
}

.wallet-button-small {
    display: none;
}