.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    box-shadow: 0px -6px 17px 2px #000000;
}

.menu-item a {
    display: flex;
    align-items: center;
}

.lander .banner-resized {
    display: none;
}

.navbar-logo {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.navbar-menu {
    display: flex;
    align-items: center;
    margin-right: 20px;

}

.navbar-menu ul {
    list-style: none;
    display: flex;

}

.navbar-menu li, a {
    font-size: 18px;
}
.navbar-menu li {
    margin: auto 10px;
}
.menu-item:hover {
    cursor: pointer;
}
.menu-item {
    display: flex;
    align-items: center;
}



.menu-item img {
    padding-right: 10px;
    width: 33px;
}
