.roadmap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, #F4F4F4 0%, #F1F1F1 100%);
    padding-bottom: 150px;
}

.timeline_elements {
    position: absolute;
    width: 2px;
    height: 2700px;
    top: 425px;
    background-color: #212121;
}

.dot-left {
    left: -88px;
}

.dot-right {
    right: -88px;
}

.dot {
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: #212121;
    border-radius: 50%;
    top: 49%;
}

.roadmap_headline {
    font-size: 50px;
    text-align: center;
    margin: 100px 0;
}

.roadmap_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.new-line {
    white-space: pre;
}

.roadmap_title {
    position: absolute;
    top: 50%;
    width: 120px;
    color: #1E1E1E;
}

.roadmap_subtitle {
    margin-bottom: 20px;
    font-weight: bold;
}

.roadmap_title_left {
    right: 660px;
}

.roadmap_title_right {
    left: 660px;
}

.roadmap_phase {
    position: relative;
    max-width: 554px;
    background: #FFFFFF;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: -30px 0;
    padding: 35px;
    background-color: #1D1D1D;
}

.roadmap_left {
    margin-right: 700px;
}

.roadmap_right {
    margin-left: 700px;

}

.arrow-right {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid #DA392B;
    top: 47%;
    left: 575px;
}

.arrow-left {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid #DA392B;
    top: 47%;
    right: 575px;
}