/* Global font settings */
@font-face {
    font-family: "ShareTech M";
    src: url("./fonts/ShareTechMono-Regular.ttf");
}

@font-face {
    font-family: "Syne M";
    src: url("./fonts/SyneMono-Regular.ttf");
}

div, p {
    font-family: "ShareTech M";
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Syne M";
}

h2 {
    font-size: 48px;
}

h3 {
    font-size: 36px;
}

/* Global format spacing */
div, * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 24px;
}

a {
    text-decoration: none;
    color: #000000;
}