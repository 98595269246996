.mint-counter-content {
    padding: 25px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px -5px 8px -2px rgba(0, 0, 0, 0.38);
    margin-top: -7px;
    z-index: 10;
    position: relative;
    height: 700px;
}

.mint-countdown {
    margin: 10px;
}

.yaku-poster img {
    min-width: 200px;
    max-width: 800px;
    margin: 60px 0;
    height: auto;
    width: 100%;
}

.mint-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mint-headline {
    margin-top: 20px;
}

.mint-yaku-gif {
    width: 300px;
    margin: 20px 0;
}

.coming-soon {
    margin-top: 0px;
}

#cf {
    position:relative;
}

#cf img {
    width: 250px;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    cursor: pointer;
}

#cf img.mint-active:hover {
    opacity:0;
}

.mintStatus {
    margin: 10px 0;
}

.mint-feature .mintStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
}