@media only screen and (max-width: 880px) {
    .wallet-button-wallet-address {
        display: none;
    }

    .wallet-button-connected{
        display: none;
    }

    .wallet-button-connect{
        display: none;
    }

    .wallet-button {
        width: auto;
    }

    .wallet-button-small {
        display: block;
        width: 25px;
        cursor: pointer;
    }
}