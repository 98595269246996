@media only screen and (max-width: 1280px) {
    .roadmap_left {
        margin-right: 0px;
    }

    .roadmap_right {
        margin-left: 0px;
    }

    .arrow-right, .arrow-left, .dot, .dot-right, .dot-left, .roadmap_title{
        display: none;
    }

    .roadmap_phase {
        margin: 25px 20px;
    }

    .timeline_elements {
        height: 1px;
    }
}

@media only screen and (max-width: 600px) {

}