.about {
    position: relative;
    margin-top: -7px;
    z-index: 10;
    box-shadow: 0px -5px 8px -2px rgba(0, 0, 0, 0.13);
    background: radial-gradient(50% 50% at 50% 50%, #D8D8D8 0%, #CECECE 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-image-box-large {
    padding: 100px 15px 40px 15px;
}

.about-image-box-small {
    display: none;
}

.about-image-box-large img {
    width: 400px;
    margin: 0 0;
}

.about-headline {
    margin: 50px 0 5px 0;
}

.about-content {
    width: 70%;
    padding-bottom: 120px;
}

.about-content p {
    margin: 30px 0;
}