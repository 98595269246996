.footer {
    width: 100%;
    height: 94px;
    background: #323232;
    box-shadow: 0px 3px 9px 2px #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.footer-toc {
    margin-bottom: 10px;
}

.footer-toc, .footer-copyright, .footer-toc a {
    font-size: 18px;
    color: #FFFFFF;
}