.team {
    padding-bottom: 100px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, #1D1D1D 0%, #1E1E1E 100%);
}

.team-title {
    margin: 80px 0;
}

.team-banner-image {
    width: 100%;
    background-color: #1D1D1D;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 50px;
}

.team-banner-image img {
    max-width: 550px;
    width: 100%;
}

.team-item {
    display: flex;
}

.team-item-avatar {
    margin: 20px 10px;
}

.team-item-details {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.team-title {

}

.team-content {

}


.team-item-details-name {
    font-family: 'Syne Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;

    color: #FFFFFF;
}

.team-item-details-title {
    font-family: 'Share Tech Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;

    color: #A7A7A7;
}