.features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, #1D1D1D 0%, #1E1E1E 100%);
    color: #FFFFFF;
}

.features-headline {
    margin: 150px 0 50px 0;
}

.features-content {
    display: flex;
    justify-content: center;
    top: 0px;
    flex-wrap: wrap;
    flex-grow: 1;
    max-width: 1200px;
    margin-bottom: 100px;
}

.feature-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
}

.feature-image {
    margin: 15px;
}

.feature-image img {
    width: 140px;
}

.feature-title {
    margin: 10px;
    font-family: 'Syne Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
}

.feature-description {
    font-family: 'Share Tech Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.017em;
    color: #7B7B7B;
    max-width: 250px;
}