@media only screen and (max-width: 1249px) {
    .about-image-box-large img {
        width: 150px;
        margin: 0 0;
    }
}

@media only screen and (max-width: 675px) {
    .about .about-image-box-large {
        display: none;
    }

    .about .about-image-box-small {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
    }

    .about .about-image-box-small img {
        margin: 25px;
        width: 250px;
    }
}

@media only screen and (max-width: 425px) {
    .about-headline {
        text-align: center;
    }
}